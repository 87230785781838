import Dashboard from '../common/dashboard';
import TuneIcon from '@mui/icons-material/Tune';
import BuildIcon from '@mui/icons-material/Build';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import GroupIcon from '@mui/icons-material/Group';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PaymentsIcon from '@mui/icons-material/Payments';
import ProviderCards from './providerCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import './tools-setup.css';
import Box from '@mui/material/Box';
import { Button, TextField, SxProps } from '@mui/material';
import styled from '@emotion/styled';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ConfigurationCard from './configurationCard';
import SupportCards from './supportCards';
import Switch from '@mui/material/Switch';

export const SubmitButton = styled(Button)({
  background: '#4B4EFC',
  border: '8px',
  height: '3rem',
  width: '100%',
  '&:hover': {
    background: '#4B4EFC',
  },
  'text-transform': 'unset',
  'font-size': '20px',
  'font-weight': '500',
  'letter-spacing': '0.02em',
});

function ToolSetup() {
  const sidebarItems = [
    { icon: SpaceDashboardIcon, label: 'Dashboard', link: '/' },
    { icon: BuildIcon, label: 'Tools', link: '/settings' },
    { icon: GroupIcon, label: 'User', link: '/profile' },
    {
      icon: AccountBalanceWalletIcon,
      label: 'Billing',
      link: '/notifications',
    },
    {
      icon: PaymentsIcon,
      label: 'Audit logs',
      link: '/notifications',
    },
    {
      icon: ImportContactsIcon,
      label: 'Finance',
      link: '/notifications',
    },
    {
      icon: TuneIcon,
      label: 'Project Settings',
      link: '/notifications',
    },
  ];

  const providers = [
    {
      name: 'Hetzner',
      logo: 'https://s3-alpha-sig.figma.com/img/d081/9353/7e3348f41a9509c753b83692d5ba438d?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=F0DHD9d9sTjJegHJLxgeii2VtZn2xrdAyrGWHAPWP0h95~MMqbOgTm9meyUXFgqSihZM4f5dQKtLThbLsx~iuvX8ExI75M0gyfncbU9aTXRzQ2dIxqMVdhEsYwFixok9mleINJtW5fJ9SWOK~qu2kvaA~a-d7zjYm2nJQE26VN5B2ycAQA1ypE4Qae7pjt7Y5YAIZAhB0pPtwpAixNzBIBeb5VB34kcHsVHYnldpXniNXklWHH2Lzp8JWMNMR031GzOWn1I5CjzYodxnQyA9tnhXfza5v4YPCit6j4A2YtFURIgzEQruaJ83EyjyHkRfyM~fU92L-hHtEb-4mM2gMQ__',
    },
    {
      name: 'Digital Ocean',
      logo: 'https://s3-alpha-sig.figma.com/img/a93a/7fd9/0b2b2d0e4fbb437d47ff9f5363b31d15?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iRtI05kEKJ9l28km5Lu8j6liLnDYBnB1Y0TgaWwR15lOkU7BAnMkoNlcDiVoSl1aRboaM73z0NyZrWzsUA7qez5fmHWyyIaYqgp8RfSkqhBqpyBNdrcy5wNNbTB2eybc8fhdmyFStwmzBi45ZeS3wYugcavCW-f~Oya-1Csne9-cLmHrCD0Vw-bZd9WgEmulYWsTAmB8liB2T9cwUG3DMHzD4P2XHbnxNqxvCuCutTIcghHN-Qu7upEk8NT6-L5lsq7B0ddBmy3ECgxRXJlBfdG6GZk1mpnVeITvgZZrytGgOwy8Zsri9ImJglPwMANlLh1NH5YxpgWGC0SqhkpgqA__',
    },
  ];
  const SystemConfig = [
    {
      name: 'AMD EPYC',
      detail: [
        {
          name: 'MICRO-1C-2G-AMD',
          config: [
            {
              name: '1 CPU',
            },
            {
              name: '2 GB RAM',
            },
            {
              name: '20 GB Storage',
            },
            {
              name: '20 TB Bandwidth',
            },
            {
              name: 'Intel Xeon',
            },
          ],
        },
        {
          name: 'MICRO-1C-2-AMD',
          config: [
            {
              name: '1 CPU',
            },
            {
              name: '2 GB RAM',
            },
            {
              name: '20 GB Storage',
            },
            {
              name: '20 TB Bandwidth',
            },
            {
              name: 'Intel Xeon',
            },
          ],
        },
        {
          name: 'MICRO-1-2G-AMD',
          config: [
            {
              name: '1 CPU',
            },
            {
              name: '2 GB RAM',
            },
            {
              name: '20 GB Storage',
            },
            {
              name: '20 TB Bandwidth',
            },
            {
              name: 'Intel Xeon',
            },
          ],
        },
      ],
    },
    {
      name: 'Intel Zenon',
      detail: [
        {
          name: 'Core i7 10002U',
          config: [
            {
              name: '1 CPU',
            },
            {
              name: '8 GB RAM',
            },
            {
              name: '20 GB Storage',
            },
            {
              name: '20 TB Bandwidth',
            },
            {
              name: 'Intel Xeon',
            },
          ],
        },
      ],
    },
  ];
  const regions = [
    {
      name: 'Europe',
      list: [
        {
          code: 'fsn1',
          name: 'Germany - Falkenstein',
          logo: 'https://th.bing.com/th/id/OIP.wvc6yODlde9_Hj1T2nQ0lwHaHw?rs=1&pid=ImgDetMain',
        },
        {
          code: 'fsn1',
          name: 'Italy - Paris',
          logo: 'https://th.bing.com/th/id/OIP.wvc6yODlde9_Hj1T2nQ0lwHaHw?rs=1&pid=ImgDetMain',
        },
        {
          code: 'fsn1',
          name: 'Poland- ansckac',
          logo: 'https://th.bing.com/th/id/OIP.wvc6yODlde9_Hj1T2nQ0lwHaHw?rs=1&pid=ImgDetMain',
        },
      ],
    },
    {
      name: 'Asia',
      list: [
        {
          code: 'dle',
          name: 'India - Delhi',
          logo: '',
        },
      ],
    },
    {
      name: 'America',
      list: [
        {
          code: 'abcdef',
          name: '',
          logo: '',
        },
      ],
    },
  ];
  const support = [
    {
      name: 'Level 1 Support',
      description: [
        {
          name: '7 days of remote backup',
        },
        {
          name: 'Documentation & Community forums',
        },
      ],
      price: 'Included',
    },
    {
      name: 'Level 2 Support',
      description: [
        {
          name: '7 days of remote backup',
        },
        {
          name: 'Documentation & Community forums',
        },
      ],
      price: '10$/hr',
    },
    {
      name: 'Level 3 Support',
      description: [
        {
          name: '7 days of remote backup',
        },
        {
          name: 'Documentation & Community forums',
        },
      ],
      price: '20$/hr',
    },
  ];
  const [providerValue, setProvider] = useState('Please select a provider');
  const [region, setRegion] = useState('Please select a region');
  const [plan, setPlan] = useState('Please select configuration');
  const [specs, setSpecs] = useState([]);
  const [regionValue, setRegionValue] = React.useState('Europe');
  const [configTab, setConfigTab] = useState('AMD EPYC');
  const [supportLevel, setSupportLevel] = useState('Select Support');
  const handleRegionChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setRegionValue(newValue);
  };
  const handleConfigChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setConfigTab(newValue);
  };
  return (
    <Dashboard
      sidebarItems={sidebarItems}
      contentJsx={
        <div className="tool-setup-container">
          <div className="dashboard-heading">
            <div className="tool-details-upper-text">
              Tools/ Kafka/ Create new instance
            </div>
            <div className="tool-details-lower-text">Tool Setup</div>
          </div>
          <div>
            <Stack spacing={6} style={{ marginTop: '30px' }}>
              <Stack spacing={2}>
                <h3>Provide Tool Name & Admin email</h3>
                <Stack direction={'row'} spacing={4}>
                  <SetupTextField label="Tool Name" variant="standard" />
                  <SetupTextField label="Admin email" variant="standard" />
                </Stack>
              </Stack>{' '}
              <Stack spacing={2}>
                <h3>Select cloud provider</h3>
                <div className="provider-list-container">
                  {providers.map((provider, index) => (
                    <div
                      className={`provider-logo-container ${providerValue === provider.name ? 'selected' : ''}`}
                      onClick={() => {
                        setProvider(provider.name);
                      }}>
                      <img src={provider.logo} alt="" />
                    </div>
                  ))}
                </div>
              </Stack>
              <Stack spacing={2}>
                <h3>Select Region</h3>{' '}
                <TabContext value={regionValue}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleRegionChange} aria-label="region">
                      {regions.map((region, index) => (
                        <Tab value={region.name} label={region.name} />
                      ))}
                    </TabList>
                  </Box>
                  {regions.map((region, idx) => (
                    <TabPanel value={region.name}>
                      <ProviderCards data={region.list} setRegion={setRegion} />
                    </TabPanel>
                  ))}
                </TabContext>
              </Stack>
              <Stack spacing={2}>
                <h3>Select system configuration</h3>
                <TabContext value={configTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleConfigChange} aria-label="config">
                      {SystemConfig.map((config, index) => (
                        <Tab value={config.name} label={config.name} />
                      ))}
                    </TabList>
                  </Box>
                  {SystemConfig.map((config, idx) => (
                    <TabPanel value={config.name}>
                      <ConfigurationCard
                        data={config.detail}
                        setConfig={setPlan}
                        setSpecs={setSpecs}
                      />
                    </TabPanel>
                  ))}
                </TabContext>
              </Stack>
              <Stack spacing={2}>
                <h3>Select Support</h3>
                <SupportCards data={support} setSupport={setSupportLevel} />
              </Stack>
              <Stack spacing={2}>
                <h3>Configure Network Volume</h3> <Switch />
              </Stack>
            </Stack>
            <div className="setup-overview-container">
              <div className="setup-details">
                <Stack spacing={2} sx={{ padding: '2.5vw' }}>
                  <Stack spacing={1}>
                    <h5>Tool Name</h5>
                    <img
                      src="https://logos-download.com/wp-content/uploads/2020/06/Redis_Logo.png"
                      alt=""
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <h5>Provider</h5>
                    <h3>{providerValue}</h3>
                  </Stack>
                  <Stack spacing={1}>
                    <h5>Region</h5>
                    <h3>{region}</h3>
                  </Stack>
                  <Stack spacing={1}>
                    <h5>Plan</h5>
                    <h3>{plan}</h3>
                    <Stack spacing={0.5} sx={{ paddingLeft: '10px' }}>
                      {specs.map((configItem, index) => (
                        <Typography
                          variant="body2"
                          key={index}
                          sx={{ color: '#6B7281' }}
                          style={{ fontSize: '15px' }}
                          className="config-list">
                          • {configItem.name}
                        </Typography>
                      ))}
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <h5>Support</h5>
                    <h3>{supportLevel}</h3>
                  </Stack>
                </Stack>
              </div>
              <div className="setup-price-container">
                <Stack spacing={0.4}>
                  <h4>Estimated hourly price</h4>
                  <div className="setup-estimated-price">
                    <h2>0.0011 USD</h2>
                  </div>
                  <SubmitButton type="submit" variant="contained">
                    Submit
                  </SubmitButton>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export const SetupTextField = styled(TextField)({
  '&& .MuiInput-underline:hover:before': {
    borderBottom: '1px solid #6B7281',
  },
  '& label.Mui-focused': {
    color: '#6B7281',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6B7281',
  },
  width: '25%',
});
export default ToolSetup;
