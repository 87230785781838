import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { Stack } from '@mui/material';
function ProviderCard({ data, setRegion }) {
  const [selectedProvider, setSelectedProvider] = useState(null);

  const handleCardClick = (provider) => {
    setRegion(provider.name);
    setSelectedProvider(provider.name);
  };
  return (
    <Stack spacing={2}>
      {data.map((provider, idx) => (
        <Card
          sx={{
            borderRadius: '20px',
            border:
              selectedProvider === provider.name
                ? '2px solid blue'
                : '1px solid #E5E6EA',
            cursor: 'pointer',
          }}
          onClick={() => handleCardClick(provider)}>
          <CardContent style={{ paddingBottom: '0px', padding: '20px' }}>
            <Grid
              spacing={2}
              style={{
                margin: '0px',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
              }}>
              <Grid
                xs={12}
                sm={8}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  gap: '7px',
                }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: '#6B7281',
                    fontWeight: '600',
                    fontSize: '15px',
                  }}>
                  {provider.code}
                </Typography>{' '}
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: '#1F2937',
                    fontWeight: '600',
                    fontSize: '20px',
                  }}>
                  {provider.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                style={{ padding: '0px' }}>
                <img
                  src={provider.logo}
                  alt="Logo"
                  style={{ minWidth: '100%' }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}

export default ProviderCard;
