// App.js

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login/login';
import Signup from './components/signup/signup';
import {
  OrganisationList,
  OrganisationCreate,
} from './components/organisation';
import ManagedToolsDashboard from './components/dashboard/managed-tools-dashboard';
import { AuthProvider } from './components/login/auth-provider';
import { NotificationProvider } from './components/notification/notification-provider';
import themeData from './theme.json';
import ToolsDetails from './components/dashboard/toolsDetails';
import UserProfile from './components/profile/userProfile';
import Role from './components/role/role';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Groups from './components/groups/groups';
import CreateNewRole from './components/role/create-new';
import RolesAndPermissions from './components/role/RoleList';
import { Box } from '@mui/material';
import Dashboard from './components/common/dashboard';
import {
  AccountBalanceWalletOutlined,
  PaymentsOutlined,
  ImportContactsOutlined,
  GroupOutlined,
  TuneOutlined,
  SpaceDashboardOutlined,
  BuildOutlined,
} from '@mui/icons-material';
import { SidebarItem } from './components/dashboard/types';
import GroupList from './components/groups/GroupList';
import CreateUserGroup from './components/groups/CreateGroup';
import { fetchCurrentUserDetails } from './api/users';
import { LOCAL_STORAGE_ITEM_ENUM } from './api/helper';
import ProtectedRoute from './components/common/ProtectedRoute';
import PublicRoute from './components/common/PublicRoute';
import ProfileForm from './components/user-details/UserDetails';
import ToolSetup from './components/tools/tools-setup';
import ForgotPassword from './components/login/forgot-password';
import PasswordReset from './components/signup/reset-password';

const sidebarItems: SidebarItem[] = [
  { icon: SpaceDashboardOutlined, label: 'Dashboard', link: '/' },
  { icon: BuildOutlined, label: 'Tools', link: '/settings' },
  { icon: GroupOutlined, label: 'User', link: '/profile' },
  {
    icon: AccountBalanceWalletOutlined,
    label: 'Billing',
    link: '/notifications',
  },
  {
    icon: PaymentsOutlined,
    label: 'Audit logs',
    link: '/notifications',
  },
  {
    icon: ImportContactsOutlined,
    label: 'Finance',
    link: '/notifications',
  },
  {
    icon: TuneOutlined,
    label: 'Project Settings',
    link: '/notifications',
  },
];


const Wrapper = (Component) => {
  return <Box>
      <Dashboard
        sidebarItems={sidebarItems}
        contentJsx={<Component />}
      />
    </Box>
  
}

const theme = createTheme({
  typography: {
    fontFamily: 'Manrope,sans-serif', // Example: using Roboto font
    fontSize: 14, // Example: setting default font size
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    // Other typography options
  },
  palette: {
    primary: {
      main: '#4B4EFC', // Example: setting primary color
    },
    secondary: {
      main: '#E1E1FE', // Example: setting secondary color
    },
  },
  // Other theme customizations
});

function App() {
  function setThemeFromJSON(theme) {
    Object.entries(theme).forEach(([key, value]) => {
      if (typeof value === 'string') {
        document.documentElement.style.setProperty(`--${key}`, value);
      }
    });
  }

  useEffect(() => {
    setThemeFromJSON(themeData);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {' '}
      <BrowserRouter>
        <NotificationProvider>
          <AuthProvider>
            <Routes>
              <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<PasswordReset />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/tools/setup" element={<ToolSetup />} />
                <Route path="/organisation-list" element={<OrganisationList />} />
                <Route
                  path="/organisation-create"
                  element={<OrganisationCreate />}
                />

                <Route path="/" element={<ManagedToolsDashboard />} />
                <Route path="/tools/:toolName" element={<ToolsDetails />} />
                <Route path="/user/profile" element={Wrapper(UserProfile)} />{' '}
                <Route path="/users" element={<Role />} />
                <Route path="/roles/create" element={<CreateNewRole />} />
                <Route path="/roles/edit/:roleId" element={<CreateNewRole />} />
                <Route path="/roles" element={Wrapper(RolesAndPermissions)} />
                <Route path="/groups/create" element={Wrapper(CreateUserGroup)} />
                <Route path="/groups/:groupId" element={Wrapper(Groups)} />
                <Route path="/groups" element={Wrapper(GroupList)} />
                <Route path="/user/details" element={<ProfileForm />} />
              </Route>
            </Routes>
          </AuthProvider>
        </NotificationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
