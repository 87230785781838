import * as config from '../config';
import { getAuthenticatedUserHeaders } from './helper';
import { API_ENDPOINTS } from './apiEndpoints';
import apiProvider from './provider';

export const managedToolsApi = async (activeTab: string) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url:
        config.coreBackendUrl +
        `${API_ENDPOINTS.MANAGED_TOOLS.GET_ALL}?tab=${activeTab}`,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw error;
  }
};
