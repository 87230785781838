import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import { useState } from 'react';
function ConfigurationCard({ data, setConfig, setSpecs }) {
  const [selectedConfig, setSelectedConfig] = useState(null);
  const handleCardClick = (option) => {
    setSpecs(option.config);
    setConfig(option.name);
    setSelectedConfig(option.name);
  };
  return (
    <Stack spacing={2}>
      {data.map((option, idx) => (
        <Card
          sx={{
            borderRadius: '20px',
            border:
              selectedConfig === option.name
                ? '2px solid blue'
                : '1px solid #E5E6EA',
          }}
          onClick={() => handleCardClick(option)}>
          <CardContent style={{ paddingBottom: '0px', padding: '20px' }}>
            <Grid
              spacing={2}
              style={{
                margin: '0px',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
              }}>
              <Grid
                xs={12}
                sm={8}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: '#1F2937',
                    fontWeight: '600',
                    fontSize: '20px',
                  }}>
                  {option.name}
                </Typography>
                <Stack
                  direction="row"
                  spacing={0}
                  mt={1}
                  sx={{ flexWrap: 'wrap', gap: '10px' }}>
                  {option.config.map((configItem, index) => (
                    <Typography
                      variant="body2"
                      key={index}
                      sx={{ color: '#6B7281' }}
                      style={{ fontSize: '15px' }}>
                      • {configItem.name}
                    </Typography>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}

export default ConfigurationCard;
